import React from "react";
import logoPath from "../../img/logo.png";
import fiverrLogoPath from "../../img/fiverr.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faFileLines,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";

const packages = [
  {
    price: 70,
    title: "Essential Front-End",
    description:
      "Responsive HTML/CSS for up to 3 pages with basic Javascript functionality.",
    deliveryTime: 3,
    numOfRevisions: 2,
    numOfPages: 3,
  },
  {
    price: 130,
    title: "Interactive Front-End",
    description:
      "Custom front-end development for up to 5 pages, responsive design, and basic SEO optimization.",
    deliveryTime: 4,
    numOfRevisions: 4,
    numOfPages: 5,
  },
  {
    price: 300,
    title: "Pro Front-End",
    description:
      "Custom React front-end development for unlimited pages, premium UI/UX, SEO, performance optimization.",
    deliveryTime: 10,
    numOfRevisions: 6,
    numOfPages: 10,
  },
];

export function Logo() {
  return (
    <section className="flex flex-col md:flex-row md:min-h-screen">
      <div className="flex justify-center items-center md:min-h-screen w-full bg-indigo-600 p-6 md:w-1/3 lg:w-1/2">
        <img className="w-10 md:w-40" src={logoPath} alt="Code with Craig" />
      </div>
      <div className="flex justify-center items-center md:min-h-screen w-full p-6 md:p-0 md:w-2/3 lg:w-1/2">
        <div className="max-w-lg overflow-hidden shadow-lg p-6 border border-gray-100 rounded-xl">
          <div className="">
            <div className="font-bold text-xl mb-2">Code with Craig</div>
            <p className="text-gray-700 text-base">
              I'm Craig, your go-to expert for crafting visually stunning and
              functionally impeccable websites. With a passion for
              pixel-perfection, I offer tailored packages to meet your unique
              needs.
            </p>
          </div>
          <ul className="mt-2">
            {packages.map((item) => {
              return (
                <li className="mb-4" key={`item=${item.title}`}>
                  <a
                    href="https://www.fiverr.com/codewithcraig/transform-your-designs-into-responsive-websites"
                    className="p-4 block shadow hover:bg-gray-50 transition-all rounded-xl"
                  >
                    <div className="flex items-center justify-between mb-2">
                      <p className="font-bold">{item.title}</p>
                      <p className="font-bold">{`£${item.price}`}</p>
                    </div>
                    <p className="text-sm mb-4 text-gray-700">
                      {item.description}
                    </p>
                    <ul className="flex justify-between flex-col md:flex-row text-gray-600">
                      <li className="text-sm mb-2 md:mb-0">
                        <FontAwesomeIcon
                          className="mr-2 text-black"
                          icon={faFileLines}
                        />
                        {item.numOfPages} Pages
                      </li>
                      <li className="text-sm mb-2 md:mb-0">
                        <FontAwesomeIcon
                          className="mr-2 text-black"
                          icon={faClock}
                        />
                        {item.deliveryTime} days delivery
                      </li>
                      <li className="text-sm mb-2 md:mb-0">
                        <FontAwesomeIcon
                          className="mr-2 text-black"
                          icon={faPenToSquare}
                        />
                        {item.numOfRevisions} Revisions
                      </li>
                    </ul>
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="gr flex items-center mt-2 text-gray-700">
            <p className="">Packages powered by </p>
            <img
              className="object-contain w-12 ml-1"
              src={fiverrLogoPath}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

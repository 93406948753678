import React from "react";
// import { Footer, Header, Hero, KeyFeatures } from "./components";
import { Logo } from "./components";

function App() {
  return (
    <>
      <Logo />
      {/* <Header />
      <Hero />
      <KeyFeatures />
      <Footer /> */}
    </>
  );
}

export default App;
